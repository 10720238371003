import { render, staticRenderFns } from "./ClientsDetail.vue?vue&type=template&id=3822cde6&scoped=true&"
import script from "./ClientsDetail.vue?vue&type=script&lang=js&"
export * from "./ClientsDetail.vue?vue&type=script&lang=js&"
import style0 from "./ClientsDetail.vue?vue&type=style&index=0&lang=scss&"
import style1 from "./ClientsDetail.vue?vue&type=style&index=1&id=3822cde6&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3822cde6",
  null
  
)

export default component.exports